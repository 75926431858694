export default {
    dataLoading: false,
    configs: {},
    agents: [],
    patients: [],
    recentpatients: [],
    consultations: [],
    emplacements: [],
    patients_pending: [],
    patients_sous_traitement: [],
    emplacement_pharmacies: [],
    pharmacies: [],
    examens: [],
    all_examens: [],
    labo_examens: [],
    pending_prescriptions: [],
    schedules: [],
    all_facturations: [],
    site_facturations: [],
    patient_docs: {},
    rcode: "",
    symptomes: [
        { id: 1, value: "Fièvre" },
        { id: 2, value: "Toux sèche" },
        { id: 3, value: "Douleur thoracique" },
        { id: 4, value: "Difficulté à respirer" },
        { id: 5, value: "Fatigue" },
        { id: 6, value: "Mal de tête" },
        { id: 7, value: "Douleurs musculaires ou articulaires" },
        { id: 8, value: "Nausées" },
        { id: 9, value: "Vomissements" },
        { id: 10, value: "Diarrhée" },
        { id: 11, value: "Perte de goût ou d'odorat" },
        { id: 12, value: "Rougeurs cutanées" },
        { id: 13, value: "Éruption cutanée" },
        { id: 14, value: "Démangeaisons" },
        { id: 15, value: "Maux de gorge" },
        { id: 16, value: "Congestion nasale" },
        { id: 17, value: "Éternuements fréquents" },
        { id: 18, value: "Douleur abdominale" },
        { id: 19, value: "Saignements anormaux" },
        { id: 20, value: "Perte de poids inexpliquée" },
        { id: 21, value: "Vision trouble" },
        { id: 22, value: "Sensation de vertige" },
        { id: 23, value: "Perte de conscience" },
        { id: 24, value: "Troubles de l'élocution" },
        { id: 25, value: "Confusion mentale" },
        { id: 26, value: "Irritabilité" },
        { id: 27, value: "Anxiété" },
        { id: 28, value: "Dépression" },
        { id: 29, value: "Insomnie" },
        { id: 30, value: "Palpitations" },
        { id: 31, value: "Hypertension artérielle" },
        { id: 32, value: "Hypotension artérielle" },
        { id: 33, value: "Tremblements" },
        { id: 34, value: "Sueurs nocturnes" },
        { id: 35, value: "Changements dans les habitudes urinaires" },
        { id: 36, value: "Saignements lors de la miction" },
        { id: 37, value: "Envies fréquentes d'uriner" },
        { id: 38, value: "Démangeaisons génitales" },
        { id: 39, value: "Écoulement génital anormal" },
        { id: 40, value: "Douleur pendant les rapports sexuels" },
        { id: 41, value: "Retard ou irrégularité menstruelle" },
        { id: 42, value: "Écoulement de lait des mamelons" },
        { id: 43, value: "Gonflement des seins" },
        { id: 44, value: "Sensation de brûlure en urinant" },
        { id: 45, value: "Saignements vaginaux anormaux" },
        { id: 46, value: "Sensation de pression ou de douleur dans le bas du dos" },
    ],
};