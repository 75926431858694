<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <a href="javascript:void(0)" class="logo logo-dark">
              <span class="logo-sm">
                <img src="assets/logo.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="assets/logo.png" alt="" height="17" />
              </span>
            </a>

            <a href="javascript:void(0)" class="logo logo-light">
              <span class="logo-sm">
                <img src="assets/logo.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="assets/logo.png" alt="" height="17" />
              </span>
            </a>
          </div>

          <button type="button" @click="collapseMenu"
            class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <!-- App Search-->
          <form class="app-search d-none d-md-block">
            <div v-if="user !== null" class="position-relative d-flex justify-content-center align-items-center">
              <!-- <input type="text" class="form-control" placeholder="Recherche..." autocomplete="off"
                                id="search-options" value="">
                            <span class="mdi mdi-magnify search-widget-icon"></span>
                            <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                id="search-close-options"></span> -->
              <img v-if="user.hopital" :src="user.hopital.hopital_logo" class="img-fluid" alt="logo"
                style="height: 50px" />
            </div>
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
              <div data-simplebar style="max-height: 320px">
                <!-- item-->
                <div class="dropdown-header">
                  <h6 class="text-overflow text-muted mb-0 text-uppercase">
                    Recent Searches
                  </h6>
                </div>

                <div class="dropdown-item bg-transparent text-wrap">
                  <a href="javascript:void(0)" class="btn btn-soft-secondary btn-sm rounded-pill">how to setup <i
                      class="mdi mdi-magnify ms-1"></i></a>
                  <a href="javascript:void(0)" class="btn btn-soft-secondary btn-sm rounded-pill">buttons <i
                      class="mdi mdi-magnify ms-1"></i></a>
                </div>
                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">Pages</h6>
                </div>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
                  <span>Analytics Dashboard</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                  <span>Help Center</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i class="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
                  <span>My account settings</span>
                </a>

                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-2 text-uppercase">Members</h6>
                </div>

                <div class="notification-list">
                  <!-- item -->
                  <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                    <div class="d-flex">
                      <img src="assets/images/users/avatar-doctor.png" class="me-3 rounded-circle avatar-xs"
                        alt="user-pic" />
                      <div class="flex-grow-1">
                        <h6 class="m-0">Angela Bernier</h6>
                        <span class="fs-11 mb-0 text-muted">Manager</span>
                      </div>
                    </div>
                  </a>
                  <!-- item -->
                  <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                    <div class="d-flex">
                      <img src="assets/images/users/avatar-doctor.png" class="me-3 rounded-circle avatar-xs"
                        alt="user-pic" />
                      <div class="flex-grow-1">
                        <h6 class="m-0">David Grasso</h6>
                        <span class="fs-11 mb-0 text-muted">Web Designer</span>
                      </div>
                    </div>
                  </a>
                  <!-- item -->
                  <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                    <div class="d-flex">
                      <img src="assets/images/users/avatar-doctor.png" class="me-3 rounded-circle avatar-xs"
                        alt="user-pic" />
                      <div class="flex-grow-1">
                        <h6 class="m-0">Mike Bunch</h6>
                        <span class="fs-11 mb-0 text-muted">React Developer</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="text-center pt-3 pb-1">
                <a href="javascript:void(0)" class="btn btn-primary btn-sm">View All Results <i
                    class="ri-arrow-right-line ms-1"></i></a>
              </div>
            </div>
          </form>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-search fs-22"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown">
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search ..."
                      aria-label="Recipient's username" />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user" v-if="user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-doctor.png"
                  alt="Header Avatar" />
                <span class="text-start ms-xl-2">
                  <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{
                    user.name
                    }}</span>
                  <span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text" v-if="user.role">{{ user.role.role
                    }}</span>
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="dropdown-header" v-if="user">Bienvenue {{ user.name }}!</h6>
              <a class="dropdown-item" href="javascript:void(0)"><i
                  class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Profil</span></a>
              <!-- <a class="dropdown-item" href="javascript:void(0)"><i
                                    class="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                                    class="align-middle">Patients en attente <span class="badge badge-pill bg-danger"
                                        data-key="t-hot">04</span> </span></a> -->

              <div class="dropdown-divider"></div>

              <a class="dropdown-item" href="javascript:void(0)"><i
                  class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Paramètres</span></a>
              <a class="dropdown-item" href="javascript:void(0)"><i
                  class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Verrouillage session</span></a>
              <a class="dropdown-item" href="javascript:void(0)" @click.prevent="logout
                "><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">Déconnexion</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    collapseMenu(e) {
      console.log(e);
      const screenWidth = document.documentElement.clientWidth;
      const layout = document.documentElement.getAttribute("data-layout");
      const sidebarSize = document.documentElement.getAttribute("data-sidebar-size");

      // Toggle hamburger icon
      if (screenWidth > 767) {
        document.querySelector(".hamburger-icon")?.classList.toggle("open");
      }

      // Toggle menu class based on layout
      if (layout === "horizontal") {
        document.body.classList.toggle("menu");
      }

      // Toggle sidebar size
      if (screenWidth > 767) {
        if (sidebarSize === "lg") {
          document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (sidebarSize === "sm") {
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        }
      } else {
        document.body.classList.toggle("vertical-sidebar-enable");
      }
    },

    async logout() {
      await this.$store.dispatch('auth/loggedOut');
      await this.$router.push({ name: 'login' });
    },

    handleResize() {
      const screenWidth = document.documentElement.clientWidth;
      const hamburgerIcon = document.querySelector(".hamburger-icon");
      if (hamburgerIcon) {
        if (screenWidth < 767) {
          hamburgerIcon.classList.add("open");
        } else {
          hamburgerIcon.classList.remove("open");
        }
      }
    },

    handleVerticalOverlayClick() {
      document.body.classList.remove("vertical-sidebar-enable");
    }
  },

  mounted() {
    this.handleResize();
    // Add event listener to vertical overlay elements
    const verticalOverlayElements = document.querySelectorAll(".vertical-overlay");
    verticalOverlayElements.forEach(element => {
      element.addEventListener("click", this.handleVerticalOverlayClick);
    });
    // Add resize event listener
    window.addEventListener("resize", this.handleResize);
  },

  beforeUnmount() {
    // Clean up event listeners
    this.$nextTick(() => {
      window.removeEventListener("resize", this.handleResize);
      const verticalOverlayElements = document.querySelectorAll(".vertical-overlay");
      verticalOverlayElements.forEach(element => {
        element.removeEventListener("click", this.handleVerticalOverlayClick);
      });
    });
  },

  beforeMount() {
    this.$store.dispatch("auth/refreshUser");
  },

  computed: {
    user() {
      return this.$store.getters["auth/GET_USER"];
    }
  }
};
</script>
