export default {
  categories: [],
  produits: [],
  products: [],
  pharmacies: [],
  config: {},
  users: [],
  cart: [],
  client: null,
  seller_reports: [],
  dataLoading: false,
  session: null,
};
