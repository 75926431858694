export default {
    GET_LOADING: (state) => state.dataLoading,
    GET_CONFIGS: (state) => state.configs,
    GET_AGENTS: (state) => state.agents,
    GET_CODE: (state) => state.rcode,
    GET_PATIENTS: (state) => state.patients,
    GET_RECENT_PATIENTS: (state) => state.recentpatients,
    GET_CONSULTATIONS: (state) => state.consultations,
    GET_EMPLACEMENTS: (state) => state.emplacements,
    GET_PATIENTS_PENDING: (state) => state.patients_pending,
    GET_PATIENTS_SOUS_TRAITEMENT: (state) => state.patients_sous_traitement,
    GET_EMPLACEMENT_PHARMAS: (state) => state.emplacement_pharmacies,
    GET_PHARMAS: (state) => state.pharmacies,
    GET_EXAMENS: (state) => state.examens,
    GET_ALL_EXAMENS: (state) => state.all_examens,
    GET_LABO_EXAMENS: (state) => state.labo_examens,
    GET_PENDING_PRESCRIPTIONS: (state) => state.pending_prescriptions,
    GET_SCHEDULES: (state) => state.schedules,
    GET_ALL_FACTURATIONS: (state) => state.all_facturations,
    GET_SITE_FACTURATIONS: (state) => state.site_facturations,
    GET_PATIENT_DOCS: (state) => state.patient_docs,
};